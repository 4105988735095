import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef,
} from 'react'

import styled, { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'
import AOS from 'aos'

import Header from '../Header'
import Footer from '../Footer'
import ThemeSwitch from '../ThemeSwitch'

import image from '../../assets/ear/ear-white.jpg'


import ModalVideo from '../ModalVideo'
import ContactModal from '../ContactModal'
import AboutModal from '../AboutModal'

import GlobalContext from '../../context/GlobalContext'

import GlobalStyle from '../../utils/globalStyle'

import imgFavicon from '../../assets/favicon.png'

import '../../assets/fonts/icon-font/fonts/avasta.ttf'
import '../../assets/fonts/icon-font/fonts/Grayic.ttf'
import '../../assets/fonts/icon-font/css/style.css'

import './bootstrap-custom.scss'
import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'
import '../../../node_modules/aos/dist/aos.css'

import '../../assets/fonts/icon-font/css/style.css'

import { get, merge } from 'lodash'
import LazyLoad from "vanilla-lazyload";

// the full theme object
import { theme as baseTheme } from '../../utils'

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
`

function fix_powr(){
  const script = document.createElement('script');

  script.src = "https://www.powr.io/powr.js?platform=react";
  script.async = true;

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  }

}


// options for different color modes
const modes = { light: 'light', dark: 'dark' }

// merge the color mode with the base theme
// to create a new theme object
const getTheme = (mode) =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  })

const Layout = ({ children, pageContext }) => {
  const gContext = useContext(GlobalContext)

  const [visibleLoader, setVisibleLoader] = useState(true)

  useLayoutEffect(() => {
    AOS.init()
    setVisibleLoader(false)
    var lazyLoadInstance = new LazyLoad({
      // Your custom settings go here
    });
    
  }, [])

  // Navbar style based on scroll
  const eleRef = useRef()

  useEffect(() => {
    fix_powr();

    window.addEventListener(
      'popstate',
      function (event) {
        fix_powr();
        // The popstate event is fired each time when the current history entry changes.
      },
      false
    )
    window.addEventListener(
      'pushState',
      function (event) {
        fix_powr();

        // The pushstate event is fired each time when the current history entry changes.
      },
      false
    )

    return () => {}
  }, [gContext])

  if (pageContext.layout === 'bare') {
    return (
      <ThemeProvider
        theme={
          gContext.theme.bodyDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <GlobalStyle />
        <Helmet>
          <title>EAR</title>
          <link rel="apple-touch-icon" sizes="180x180" href="/ear/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/ear/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/ear/favicon-16x16.png" />
          <link rel="manifest" href="/ear/site.webmanifest" />
          <link rel="mask-icon" href="/ear/safari-pinned-tab.svg" color="#000000"/>
          <meta name="msapplication-TileColor" content="#ffffff"/>
          <meta name="theme-color" content="#ffffff" />

        </Helmet>
        <div className="site-wrapper overflow-hidden" ref={eleRef}>
          {children}
        </div>

        <ModalVideo />
      </ThemeProvider>
    )
  }

  return (
    <>
      <ThemeProvider
        theme={
          gContext.theme.bodyDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <GlobalStyle />
        <Helmet>
          <title>EAR</title>
          <link rel="icon" type="image/png" href={imgFavicon} />

        </Helmet>
        <div className="site-wrapper overflow-hidden" ref={eleRef}>
        <div css={{display: "none"}}>
                <img id="source"
                    src={image}
                    css={{width: "0px", height: "0px", zIndex: "-5", objectFit: "cover"}}/>
                </div>


          <Header isDark={gContext.theme.headerDark} alwaysStick={gContext.theme.alwaysStick}/>
          {children}

          <Footer isDark={gContext.theme.footerDark} />
        </div>
        <AboutModal />
        <ContactModal />
        <ModalVideo />
      </ThemeProvider>
    </>
  )
}

export default Layout
