import React from 'react'
import { Link } from 'gatsby'
import { Title } from '../Core'

import earLogoLight from '../../assets/ear/white_ear_logo.svg'
import earLogoDark from '../../assets/ear/black_ear_logo.svg'
import PaintDrip from '../PaintDrip'

const Logo = ({ color = 'dark', height, className = '', ...rest }) => {
  return (
    <PaintDrip to="/" className={`${className}`} {...rest}>
      {color == 'light' && <img src={earLogoLight}></img>}
      {color == 'dark' && <img src={earLogoDark}></img>}
    </PaintDrip>
  )
}

export default Logo
