import React from 'react'
import styled from 'styled-components'
import { color, space, typography, shadow } from 'styled-system'
import { device } from '../../utils'

const Paragraph = styled.p`
  margin-bottom: 0;
  font-weight: 400;
  font-size: 1.0625rem;
  line-height: 1.71;

  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const ParagraphLg = styled(Paragraph)`
  @media ${device.lg} {
    font-size: 60px;
    font-weight: 400;
    line-height: 72px;
  }

  font-size: 35px;
  font-weight: 400;
  line-height: 40px;

  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const ParagraphSmall = styled(Paragraph)`
  font-size: 15px;
  line-height: 20px;

  @media ${device.lg} {
    font-size: 25px;
    line-height: 30px;
  }

  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const ParagraphTag = styled(Paragraph)`
  font-size: 0.8125rem;
  line-height: 1.63;
  font-weight: 700;
  text-transform: uppercase;

  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const Text = ({ variant = 'lg', ...props }) => {
  let TextRender

  switch (variant) {
    case 'lg':
      TextRender = ParagraphLg
      break
    case 'small':
      TextRender = ParagraphSmall
      break
    case 'tag':
      TextRender = ParagraphTag
      break
    case 'p':
      TextRender = Paragraph
      break
    default:
      TextRender = ParagraphLg
  }

  return <TextRender color="text" {...props} />
}

export default Text
