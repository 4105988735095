import { createGlobalStyle } from 'styled-components'

const globalStyle = createGlobalStyle`



  body {
    background-color: ${({ theme }) => theme.colors.bg} !important;

    color: ${({ theme }) => theme.colors.text} !important;
  }

  p, .p{
    color: ${({ theme }) => theme.colors.text};
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.56px;
    line-height: 30px;
    margin-bottom: 0;
  }

  ul,.ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .hide{
    padding-bottom: unset;
    overflow: hidden;  /* Hide the element content, while height = 0 */
    height: 0;
    opacity: 0;
    transition: height 0ms 400ms, opacity 400ms 0ms;
  }

  .hide.show{
    padding-bottom: 75px;
    height: auto; 
    opacity: 1;
    transition: height 0ms 400ms, opacity 600ms 0ms;

  }

  .hover{

    transition: 0.4s;

    transform: translateY(0) !important;

  }

  .hover:hover{

  box-shadow: 0 0.5em 0.5em -0.4em var(--hover) !important;
  transform: translateY(-0.25em) !important;
}


  a {
    transition: all 0.3s ease-out;
    text-decoration: none!important;

    color: ${({ theme }) => theme.colors.heading} !important;
    &:hover, &:active, &:focus{
      text-decoration: none!important;
      outline: none !important;
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }

  

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }

`

export default globalStyle
