import React from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import Logo from '../Logo'

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-out;
  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
`

const Drawer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  background: #fff;
  overflow-y: auto;
  transform: translateX(0);
  transition: all 0.3s ease-out;
  text-align: center;
  &.hidden {
    display: none;
  }
  .dropdown-menu {
    position: static !important;
    will-change: initial !important;
    transform: initial !important;
    float: none !important;
  }
`

const LogoContainer = styled.div`
  img {
    height: 30px;
  }
`

const CloseWrapper = styled.div`
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  z-index: 50;
  color: #707070;
`

const CloseButton = (props) => (
  <CloseWrapper {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g
        id="Icon_feather-plus"
        data-name="Icon feather-plus"
        transform="translate(9.546 -15.91) rotate(45)"
      >
        <path
          id="Path_18"
          data-name="Path 18"
          d="M18,7.5v21"
          fill="none"
          stroke="#707070"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          id="Path_19"
          data-name="Path 19"
          d="M7.5,18h21"
          fill="none"
          stroke="#707070"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
      </g>
    </svg>
  </CloseWrapper>
)

const Offcanvas = ({ show, onHideOffcanvas, children, ...rest }) => {
  if (typeof document !== 'undefined') {
    if (show) {
      document.querySelector('html').classList.add('has-offcanvas')
      document.body.classList.add('has-offcanvas')
    } else {
      document.querySelector('html').classList.remove('has-offcanvas')
      document.body.classList.remove('has-offcanvas')
    }
  }

  return (
    <div {...rest}>
      <Overlay className={show ? '' : 'hidden'} onClick={onHideOffcanvas} />
      <Drawer className={show ? '' : 'hidden'}>
        <Container className="h-100">
          <div className="mb-3 d-flex align-items-center justify-content-between p-3">
            <LogoContainer>
              <Logo onClick={onHideOffcanvas} />
            </LogoContainer>
            <CloseButton onClick={onHideOffcanvas} />
          </div>

          {children}
        </Container>
      </Drawer>
    </div>
  )
}

export default Offcanvas
